import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {HelperService, SingleSignOnService, SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs'
import {catchError, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'

/**
 * An SPB user is a sparbanken employee
 */

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * If we are administrators we can do stuff.
   */
  admin: boolean

  /**
   * Set to true if the user can see binding info,
   * requires role renewalToolBindings
   */
  bindings: boolean

  /**
   * Employees, anyone in the bank. Can view?
   */
  viewer?: boolean

  /**
   * Set by the HttpIncerceptor when a token is ready to be used
   */
  interceptorReady?: boolean

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean

  /**
   * The current user
   */
  user?: SparbankenUser
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false, admin: false, bindings: false})

  public isAdmin: boolean = false

  constructor(
    private router: Router,
    private ssoService: SingleSignOnService,
    private helperService: HelperService
  ) {
  }


  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing.
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST log out if the SSO service says we are logged out!
          this.logout()
          return EMPTY
        })
      )
  }


  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payload = HelperService.ValidateToken(token)
    if (payload) {
      const currentConfig: SpbConfiguration = {
        token: token,
        admin: payload.roles.includes('renewalToolAdmin'),
        viewer: payload.roles.includes('renewalToolViewer'),
        bindings: payload.roles.includes('renewalToolBinding'),
        ready: false
      }
      this.isAdmin = currentConfig.admin
      this.checkIfReady(currentConfig)
      this.configState$.next(currentConfig)
      this.setCurrentUser()
      return of(true)
    }

    this.resetToken()
    return of(false)
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): void {
    this.isAdmin = false
    this.configState$.next({ready: false, admin: false, bindings: false, user: undefined})
  }

  /**
   * However finds a user can communicate it here
   */
  public setCurrentUser(): void {
    this.helperService.getCurrentUser(`${environment.authServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          const config = this.configState$.value
          config.user = user
          this.configState$.next(config)
        }
      })
  }

  /**
   * Set the ready state of the configuration
   */
  public checkIfReady(config: SpbConfiguration): void {
    config.ready = config.admin || config.viewer || config.bindings
  }

  /**
   * Reset all admin values, including SSO and
   * go back to log-in.
   */
  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.resetToken()
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    this.router.navigate(['main', 'login']).then()
  }
}
