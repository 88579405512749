import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {LoanListItem} from './data.service'
import {BehaviorSubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {Sort} from '@angular/material/sort'
import {DateTime} from 'luxon'

export const FILTER_VERSION = 1

export const getEmptyFilter = (): FilterData => {
  return {
    version: FILTER_VERSION,
    changed: false,
    office: '',
    employee: '',
    productCode: 'all',
    bound: true,
    unbound: true,
    private: true,
    business: true,
    searchField: '',
    fromDate: null,
    toDate: null,
    sort: {active: '', direction: ''}
  }
}

export interface FilterDataBase {
  /**
   * Used to know if filter needs reset
   */
  version: number
  changed: boolean
  office: string
  employee: string
  productCode: string
  bound: boolean
  unbound: boolean
  business: boolean
  private: boolean
  searchField: string
  sort: Sort
}

export interface FilterData extends FilterDataBase {
  fromDate: DateTime | null
  toDate: DateTime | null
}

export interface StoredFilterData extends FilterDataBase {
  fromDate: string
  toDate: string
}

const FILTER_NAME = 'filter'

@Injectable({
  providedIn: 'root'
})
/**
 * The point of this was to filter the list of loans
 */
export class FilterService {

  public filteredLoans$: BehaviorSubject<LoanListItem[]> = new BehaviorSubject<LoanListItem[]>([])

  public officeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  public employeeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  public productCodeList = new BehaviorSubject<string[]>([])

  public fieldList: string[] = []

  public filter: WritableSignal<FilterData> = signal(getEmptyFilter())

  /**
   * The size of filtered loans
   */
  public count = signal<number>(0)

  private ownerTypes: string[] = ['B', 'P']

  private bindings: string[] = ['1 må', '2 må', '3 må', '1 år', '2 år', '3 år', '4 år', '5 år', '7 år', '10 år']

  private loans: LoanListItem[] = []

  private pFilter: FilterData = getEmptyFilter()

  private ils: Storage = inject(LOCAL_STORAGE)

  public setLoans(loans: BehaviorSubject<LoanListItem[]>): void {
    loans.subscribe((loans: LoanListItem[]) => {
      this.loans = loans
      this.officeList.next(this.getSet('office'))
      this.employeeList.next(this.getSet('responsible'))
      this.productCodeList.next(this.getSet('productCode'))
      // Load filter _after_ the loans so that it does not return empty
      this.loadFilter()
    })
  }

  public saveSort(sort: Sort): void {
    const existing: FilterData = JSON.parse(this.ils.getItem(FILTER_NAME)!)
    existing.sort = sort
    this.ils.setItem(FILTER_NAME, JSON.stringify(existing))
  }

  public setFilters(input: Partial<FilterData>): FilterData {
    this.pFilter = Object.assign(this.pFilter, input)
    this.ownerTypes = []
    this.bindings = []


    if (this.pFilter.bound) {
      this.bindings = ['1 år', '2 år', '3 år', '4 år', '5 år', '7 år', '10 år']
    }

    if (this.pFilter.unbound) {
      this.bindings.push('3 må', '2 må', '1 må')
    }

    if (this.pFilter.business) {
      this.ownerTypes.push('B')
    }

    if (this.pFilter.private) {
      this.ownerTypes.push('P')
    }

    // Filter the loans and save the filter
    this.filteredLoans$.next(this.applyFilters(this.pFilter))
    this.count.set(this.filteredLoans$.value.length)
    this.ils.setItem(FILTER_NAME, JSON.stringify(this.pFilter))
    return this.pFilter
  }

  public resetFilter(): void {
    this.ils.removeItem(FILTER_NAME)
    const data = this.setFilters(getEmptyFilter())
    this.filteredLoans$.next(this.applyFilters(data))
    // When we reset, we also rest our signal
    this.filter.set(data)
    this.count.set(0)
  }

  public filterForProduct(loan: LoanListItem, filter: FilterData): boolean {
    if (filter.productCode === 'all') {
      return true
    }
    if (filter.productCode === 'own') {
      return loan.productCode !== 'Borgo'
    }
    return filter.productCode === loan.productCode
  }

  private loadFilter(): void {
    // Let all know we have a filter, it is either our empty filter or the one we find in local storage
    const filter = this.getStoredFilter()
    this.filter.set(filter)
    this.pFilter = filter
    this.setFilters({})
  }

  private getStoredFilter(): FilterData {
    let filter: FilterData = getEmptyFilter()
    try {
      const stored: StoredFilterData = JSON.parse(this.ils.getItem(FILTER_NAME) as string)
      filter = {...stored} as any

      if (stored.toDate) {
        filter.toDate = DateTime.fromISO(stored.toDate, {setZone: false})
      }
      if (filter.fromDate) {
        filter.fromDate = DateTime.fromISO(stored.fromDate)
      }
      // Reset filter if version has changed.
      if (filter.version !== FILTER_VERSION) {
        filter = getEmptyFilter()
      }
    } catch {
      this.ils.setItem(FILTER_NAME, JSON.stringify(filter))
    }
    return filter
  }

  /**
   * Returns an array with unique members from the loan list items. E.g. for
   * offices ['Ystad', 'Lomma' ... ]
   *
   * @param property - The property of the loan list item.
   * @private
   */
  private getSet(property: string): string[] {
    return [...new Set(this.loans.map((loan: LoanListItem) => (loan as any)[property]))]
      .sort((a: string, b: string) => a.localeCompare(b))
  }

  private applyFilters(filter: FilterData): LoanListItem[] {
    const from = (filter.fromDate as DateTime)?.toMillis()
    const to = (filter.toDate as DateTime)?.toMillis()
    return this.loans
      .filter((loan: LoanListItem) => !from || from <= loan.renewalDate)
      .filter((loan: LoanListItem) => !to || to >= loan.renewalDate)
      .filter((loan: LoanListItem) => this.ownerTypes.includes(loan.ownerType))
      .filter((loan: LoanListItem) => this.bindings.includes(loan.frequency))
      .filter((loan: LoanListItem) => filter.office === 'all' || filter.office === loan.office)
      .filter((loan: LoanListItem) => filter.employee === 'all' || filter.employee === loan.responsible)
      .filter((loan: LoanListItem) => this.filterForProduct(loan, filter))
      .filter((loan: LoanListItem) => {
        const fields = Object.entries(loan) // Create an array of loan object properties as key-value pairs
          .filter((val: [string, any]) => this.fieldList.indexOf(val[0]) !== -1) // Filter out unnecessary properties
          .map((val: [string, any]) => (val[1] + '').toLowerCase())
        return fields.join('').includes(filter.searchField.toLowerCase()) // Join all values and see if search string is included
      })

  }
}
