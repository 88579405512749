import {enableProdMode, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer} from '@angular/core'


import {environment} from './environments/environment'
import {DatePipe, registerLocaleData} from '@angular/common'
import {WINDOW} from './app/application/window.provider'
import {LOCAL_STORAGE} from './app/application/localstorage.provider'
import {xlsxProvider} from './app/application/xlsx.provider'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {ConfigService} from './app/services/config.service'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {SpbPaginatorSv} from '@sparbanken-syd/sparbanken-syd-theme'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'
import {LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {authInterceptor} from './app/application/auth-interceptor'
import {responseInterceptor} from './app/application/response-interceptor'
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser'
import {AppComponent} from './app/app.component'
import localeFr from '@angular/common/locales/fr'
import {provideRouter, withHashLocation} from '@angular/router'
import routes from './app/app-routes'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'

registerLocaleData(localeFr)
if (environment.production) {
  enableProdMode()
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    DatePipe,
    {provide: WINDOW, useValue: window},
    {provide: LOCAL_STORAGE, useValue: localStorage},
    xlsxProvider,
    {provide: LOCALE_ID, useValue: 'fr'},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline',
        subscriptSizing: 'dynamic'
      }
    },
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () => s.bootstrap())(inject(ConfigService))
      return initializerFn()
    }),
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv},
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAnimationsAsync(),
    provideRouter(routes,
      withHashLocation())
  ]
})
  .catch(err => console.error(err))
