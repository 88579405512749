import {Routes} from '@angular/router'

export default [
  {
    path: '',
    loadComponent: () => import('./common/login/login.component').then(c => c.LoginComponent)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main-routes')
  },
  {
    path: 'bindings',
    loadChildren: () => import('./bindings/bindings-routes')
  },
  {
    path: 'ondemand',
    loadChildren: () => import('./ondemand/ondemand-routes')
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error-routes')
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full'
  }
] satisfies Routes
