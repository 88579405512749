import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {FilterService} from '../../services/filter.service'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {AsyncPipe, NgIf} from '@angular/common'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatButton} from '@angular/material/button'
import {RouterLink, RouterLinkActive} from '@angular/router'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  imports: [
    LogoComponent,
    AsyncPipe,
    MatMenuTrigger,
    MatButton,
    MatMenu,
    RouterLink,
    MatMenuItem,
    RouterLinkActive,
    NgIf
  ],
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent {
  /**
   * The constructor
   *
   * @param configService - Holds the logged in state and tells ut what to show.
   * @param filterService - So that we can reset the filter.
   */
  constructor(
    public configService: ConfigService,
    public filterService: FilterService
  ) {
  }
}
