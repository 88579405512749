/**
 * this is the Angular 7 way of getting access to the window.
 *
 * Admittedly, I do not understand shit of this!
 */
import {InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')

